import * as React from "react"
import { Link } from "gatsby"
import { StaticImage} from "gatsby-plugin-image"
import Banner from "../components/banner"
import Layout from "../components/layout"
import Seo from "../components/seo"
const banner_title  = ['Design ', 3000, 'Development ', 2000, 'Marketing ',1500];

const aboutus = () => (

<Layout>
<Seo title="Specialized in Shopify store development, web development, UI/UX design, digital marketing & marketing automation. " />
<Banner className = "service" title = {banner_title} sticky_first = "We provide" sticky_second  = "full services in" />

<section className = "about-us">

            <div className = "container">

                    <div className = "intro_grid">

                        <div className ="about_img">

                        <StaticImage
                            src="../images/about-us.png"
                            quality = "90"
                            width = "400"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
                            

                        </div>
                        <div className ="about_txt">

                                <h1>about us</h1>
                                <p>We celebrate less and strive more. We feel the responsibility that business owners need
                                     futuristic, scalable and customer centric solutions - that’s the very reason you see us 
                                     discussing visionary ideas at every nook and corner of our office. We strictly care about ROI, 
                                     in fact obsessed with it.
                                  </p>

                        </div>


                    </div>


            </div>




</section>



<section className = "about-us our-story">

            <div className = "container">

                    <div className = "intro_grid">

                        <div className ="about_img">

                        <StaticImage
                            src="../images/our-story.png"
                            quality = "90"
                            width = "400"
                            alt="A Gatsby astronaut" 
                            placeholder="tracedSVG"
                            />
                            

                        </div>
                        <div className ="about_txt">

                                <h1>our story</h1>
                                <p>Since the origin, we’ve been expanding in terms of clientele and team. Now we’ve a
                                    team of 50+ people, who are serving 100+ businesses spanning across the USA. We care about
                                     clients more than money as we see us winning in their growth.
                                  </p>

                        </div>


                    </div>


            </div>




</section>


<section className = "company_highlights">

            <div className ="container">

                    <h1>company highlights</h1>

                <div className = "co_hi_list">

                    <div className = "hig_box">

                    <StaticImage
                            src="../images/highlights-icon-1.png"
                            quality = "90"
                            width = "60"
                            alt="A Gatsby astronaut" 
                            placeholder="tracedSVG"
                            />

                        <h3>11+</h3>
                        <h4>years in experience</h4>


                    </div>

                    <div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-2.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />

    <h3>15+</h3>
    <h4>talented experts</h4>


</div>

<div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-3.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />

    <h3>25+</h3>
    <h4>awesome projects</h4>


</div>

<div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-4.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />

    <h3>500+</h3>
    <h4>projects completed</h4>


</div>

<div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-5.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut" 
        placeholder="tracedSVG"
        />

    <h3>95%</h3>
    <h4>client satisfaction</h4>


</div>

<div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-6.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut" 
        placeholder="tracedSVG"
        />

    <h3>90%</h3>
    <h4>client retention rate</h4>


</div>

<div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-7.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut" 
        placeholder="tracedSVG"
        />

    <h3>04</h3>
    <h4>office locations</h4>


</div>

<div className = "hig_box">

<StaticImage
        src="../images/highlights-icon-8.png"
        quality = "90"
        width = "60"
        alt="A Gatsby astronaut" 
        placeholder="tracedSVG"
        />

    <h3>18%</h3>
    <h4>YoY clients growth</h4>


</div>


                </div>


            </div>


</section>



<section className = "our_team">

        <div className ="container">


            <h1>our team</h1>
            <h3>our key people</h3>
            <p>We love our team and see it as the biggest asset and essence behind everything we do here. 
                Each one of us is extremely personable and never shy to go extra miles. Among us are: ecommerce architects, 
                platform specialists, designers, strategists, marketers, support engineers, maintenance gurus and more.</p>



            <ul className = "founders">

                    <li>
                    <StaticImage
        src="../images/tara.png"
        quality = "90"
        width = "250"
        height = "300"
        alt="A Gatsby astronaut" 
        placeholder="tracedSVG"
        />
        <h4>tarawanti K</h4>
            <h5>founder</h5>
                    </li>

                    <li>
                    <StaticImage
        src="../images/sanjay.png"
        quality = "90"
        width = "250"
        height = "300"
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />
        <h4>sanjay K</h4>
            <h5>chief technology officer</h5>
                    </li>

                    
                    <li>
                    <StaticImage
        src="../images/jk-250.png"
        quality = "90"
        width = "250"
        height = "300"
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />
        <h4>jetendar K</h4>
            <h5>co-founder</h5>
                    </li>

            </ul>

            <ul className = "founders teams">

<li>
<StaticImage
src="../images/talha-250.png"
quality = "90"
width = "250"
height = "300"
alt="A Gatsby astronaut" 
placeholder="tracedSVG"
/>
<h4>Muhamamd Talha Mughal</h4>
<h5>Project Manager</h5>
</li>

<li>
<StaticImage
src="../images/ehtisham-250.png"
quality = "90"
width = "250"
height = "300"
alt="A Gatsby astronaut" 
placeholder="tracedSVG"
/>
<h4>Ehtisham Ahmed</h4>
<h5>Sr. Graphics Designer</h5>
</li>


<li>
<StaticImage
src="../images/zafar-250.png"
quality = "90"
width = "250"
height = "300"
alt="A Gatsby astronaut"
placeholder="tracedSVG"
/>
<h4>Zafar Zaidi</h4>
<h5>Sr. Graphics Designer</h5>
</li>


<li>
<StaticImage
src="../images/rahul-250.png"
quality = "90"
width = "250"
height = "300"
alt="A Gatsby astronaut" 

placeholder="tracedSVG"
/>
<h4>Rahul Khatri</h4>
<h5>Associate Software Engineer</h5>
</li>


</ul>


<ul className = "founders teams">

<li>
<StaticImage
src="../images/mapdeep.png"
quality = "90"
width = "250"
height = "300"
alt="A Gatsby astronaut" 

placeholder="tracedSVG"
/>
<h4>Mandeep Saini</h4>
<h5>Sr. Full Stack Developer</h5>
</li>

<li>
<StaticImage
src="../images/deepa.png"
quality = "90"
width = "250"
height = "300"
alt="A Gatsby astronaut" 

placeholder="tracedSVG"
/>
<h4>Deepa</h4>
<h5>Associate Software Engineerr</h5>
</li>

    </ul>



      </div>

</section>

<section className = "ab_batches">

<div className ="container">

<div className = "batches">

<ul>
  <li> <StaticImage
    src="../images/1.png"
    quality = "90"
     alt="A Gatsby astronaut" 
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/2.png"
    quality = "90"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/3.png"
    quality = "90"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/4.png"
    quality = "90"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/5.png"
    quality = "90"
     alt="A Gatsby astronaut" 
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/6.png"
    quality = "90"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/7.png"
    quality = "90"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
    </ul>
</div>
</div>
</section>



<section className ="offered_services">

        <div className ="container">

                <h1>360 Ecommerce Services:</h1>

            <div className ="split">

                    <div className ="offered_detail">

                       
                        <ul className = "offered_list">

                                <li>Ecommerce Development </li>
                                <li>Web Design</li>
                                <li>Web Development</li>
                                <li>Graphic Design </li>
                                <li>Digital Marketing</li>
                                <li>Marketplaces Management </li>
                                <li>CMS Development </li>
                                <li>And more</li>


                        </ul>

                    </div>

                    <div className ="offered_img">

                    <StaticImage
    src="../images/offered_img.png"
    quality = "90"
    width ="450"
     alt="A Gatsby astronaut" 
     placeholder="tracedSVG"
     />


                    </div>
                
            </div>


            <ul className ="stacks">

                <li>
                    
                <StaticImage
    src="../images/shopify_new.png"
    quality = "100"
    width = "220"
    placeholder="tracedSVG"
   
     alt="A Gatsby astronaut" />
                </li>
                <li>
                    
                    <StaticImage
        src="../images/woo_commerce.png"
        quality = "100"
        width = "220"
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />
                    </li>
                    <li>
                    
                    <StaticImage
        src="../images/magento.png"
        quality = "100"
        width = "220"
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />
                    </li>
                    <li>
                    
                    <StaticImage
        src="../images/big_commerce.png"
        quality = "100"
        width = "220"
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />
                    </li>

            </ul>
        </div>
</section>


<section className ="prefered_choice">

        <div className = "detail_section">

                <h1>People see us as a preferred choice.</h1>
                <h2>Why??</h2>
                

                    <ul className="offered_list">
                        <li>We’re loaded with modern tools, brilliant ideas and extensive experience.</li>
                        <li>We’re in partnership with ecommerce platforms.</li>
                        <li>We own strong ecommerce acumen - earned after years of tests and trials.</li>
                        <li>We do experiments and are never afraid to go that unknown mile.</li>
                        <li>We believe every business type is different, hence demands a different approach.</li>
                    </ul>
                
        </div>

</section>


<section className = "get_a_quote">

        <div className ="container">

                <div className = "quote_box">

                    <div className = "quote_details">

                            <p>You’re missing the ecommerce magic. Let’s unlock it!
                            </p>

                    </div>

                    <div className = "quote_btn">

                            <Link to = "/contact-us">get started </Link>

                    </div>

                </div>



        </div>

</section>






</Layout>




 )


 export default aboutus